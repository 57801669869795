<template>
  <div>
    <v-list dense subheader class="my-0 py-0">
      <v-subheader style="font-size: 14px">
        <v-icon class="mr-2" small>location_on</v-icon>
        Locations</v-subheader
      >
      <v-divider></v-divider>
      <v-list-item
        @click="(countryType = 'Origin'), (countryModal = true)"
        style="height: 30px"
      >
        <v-list-item-action class="mr-1">
          <v-icon small :color="!booking.originCountry ? 'secondary' : 'grey'"
            >public</v-icon
          >
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.originCountry"
            style="font-size: 12px"
          >
            {{ booking.originCountry.name }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Origin Country
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar size="24">
            <v-img
              contain
              v-if="booking.originCountry"
              :src="`https://cdn.loglive.io/flags/4x3/${booking.originCountry.iso2.toLowerCase()}.svg`"
            ></v-img>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
      <v-list-item @click="polModal = true" style="height: 30px">
        <v-list-item-action class="mr-1">
          <v-icon small :color="!booking.portOfLoadValue ? 'secondary' : 'grey'"
            >anchor</v-icon
          >
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.portOfLoadCity"
            style="font-size: 12px"
          >
            {{ booking.portOfLoadCity }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Port Of Load
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-chip
            v-if="booking.portOfLoadValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfLoadValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfLoadValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfLoadValue }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>

      <!--Transhipment port-->
      <v-list-item
        :disabled="true"
        v-if="booking.sailingSchedule && booking.sailingSchedule.legs > 1"
        style="height: 30px"
      >
        <v-list-item-action class="mr-1">
          <v-icon small :color="!booking.sailingSchedule ? 'secondary' : 'grey'"
            >anchor</v-icon
          >
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="
              booking.sailingSchedule &&
              booking.sailingSchedule.sailingScheduleLegs &&
              booking.sailingSchedule.transhipmentCityName
            "
            style="font-size: 12px"
          >
            {{ booking.sailingSchedule.transhipmentCityName }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Transshipment
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-chip
            v-if="
              booking.sailingSchedule &&
              booking.sailingSchedule.sailingScheduleLegs[0]
                .portOfDischargeValue
            "
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="
                  booking.sailingSchedule &&
                  booking.sailingSchedule.sailingScheduleLegs[0]
                    .portOfDischargeValue
                "
                :src="`https://cdn.loglive.io/flags/4x3/${booking.sailingSchedule.sailingScheduleLegs[0].portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{
              booking.sailingSchedule.sailingScheduleLegs[0]
                .portOfDischargeValue
            }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>

      <v-list-item @click="podModal = true" style="height: 30px">
        <v-list-item-action class="mr-1">
          <v-icon
            small
            :color="!booking.portOfDischargeValue ? 'secondary' : 'grey'"
            >anchor</v-icon
          >
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.portOfDischargeCity"
            style="font-size: 12px"
          >
            {{ booking.portOfDischargeCity }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Port Of Discharge
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-chip
            v-if="booking.portOfDischargeValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.portOfDischargeValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.portOfDischargeValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.portOfDischargeValue }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>
      <v-list-item @click="fdModal = true" style="height: 30px">
        <v-list-item-action class="mr-1">
          <v-icon
            small
            :color="!booking.finalDestinationValue ? 'secondary' : 'grey'"
            >location_on</v-icon
          >
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.finalDestinationCity"
            style="font-size: 12px"
          >
            {{ booking.finalDestinationCity }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Final Destination
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-chip
            v-if="booking.finalDestinationValue"
            small
            outlined
            style="border: none"
          >
            <v-avatar size="24" left>
              <v-img
                contain
                v-if="booking.finalDestinationValue"
                :src="`https://cdn.loglive.io/flags/4x3/${booking.finalDestinationValue
                  .substring(0, 2)
                  .toLowerCase()}.svg`"
              ></v-img>
            </v-avatar>
            {{ booking.finalDestinationValue }}
          </v-chip>
        </v-list-item-action>
      </v-list-item>

      <v-list-item
        @click="(countryType = 'Destination'), (countryModal = true)"
        style="height: 30px"
      >
        <v-list-item-action class="mr-1">
          <v-icon
            small
            :color="!booking.destinationCountry ? 'secondary' : 'grey'"
            >public</v-icon
          >
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.destinationCountry"
            style="font-size: 12px"
          >
            {{ booking.destinationCountry.name }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Destination Country
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-avatar size="24">
            <v-img
              contain
              v-if="booking.destinationCountry"
              :src="`https://cdn.loglive.io/flags/4x3/${booking.destinationCountry.iso2.toLowerCase()}.svg`"
            ></v-img>
          </v-avatar>
        </v-list-item-action>
      </v-list-item>
      <v-list-item style="height: 30px">
        <v-list-item-action class="mr-1">
          <v-icon small :color="'grey'">not_listed_location</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title style="font-size: 12px">
            On Carriage
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-switch
            v-model="booking.onCarriage"
            color="primary"
            dense
            @change="$emit('bookingChange')"
          ></v-switch>
        </v-list-item-action>
      </v-list-item>
      <v-list-item @click="bookingOfficeModal = true" style="height: 30px">
        <v-list-item-action class="mr-1">
          <v-icon color="grey" small>business</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title
            v-if="booking.bookingOffice"
            style="font-size: 12px"
          >
            {{ booking.bookingOfficeName }}
          </v-list-item-title>
          <v-list-item-title v-else>-</v-list-item-title>
          <v-list-item-subtitle style="font-size: 11px">
            Carrier Booking Office
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-dialog
      v-model="countryModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px">
            Select {{ countryType }} Country
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="redPop"
            icon
            @click="(countryModal = false), (searchCountries = null)"
            ><v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            autofocus
            dense
            hide-details
            v-model="searchCountries"
            clearable
            placeholder="Search"
            prepend-inner-icon="search"
          ></v-text-field>
          <v-list dense style="max-height: 50vh; overflow-y: auto">
            <v-list-item
              v-for="country in filterCountries"
              :key="country.id"
              @click="setCountry(country)"
              style="height: 30px"
            >
              <v-list-item-action>
                <v-avatar size="24" left>
                  <v-img
                    contain
                    v-if="country.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${country.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 13px">
                  {{ country.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="filterCountries.length == 0">
              <v-list-item-content class="text-center">
                <span style="color: grey; font-size: 13px"
                  >No countries found</span
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POL Modal -->
    <v-dialog
      v-model="polModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" color="grey">anchor</v-icon> Manage POL
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="polModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="booking.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchOrigin"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-else
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              booking.consigneeProfile &&
              !booking.consigneeProfile.allOriginPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in loadingPorts"
                :key="port.id"
                @click="setPort('portOfLoad', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 13px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == booking.portOfLoadValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear
              v-if="loadingOriginPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterOriginPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterOriginPort"
                    :key="originPortKey"
                    height="500"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Origin')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'UNMARK', 'Origin')"
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterOriginPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 13px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="250"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfLoad', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 13px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- POD Modal -->
    <v-dialog
      v-model="podModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" color="grey">anchor</v-icon> Manage POD
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="podModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            v-if="booking.consigneeProfile"
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="searchDestination"
            hide-details
          ></v-text-field>
          <v-text-field
            class="my-0 py-0"
            v-else
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <div
            v-if="
              booking.consigneeProfile &&
              !booking.consigneeProfile.allDestinationPorts
            "
          >
            <v-list dense>
              <v-list-item
                v-for="port in dischargePorts"
                :key="port.id"
                @click="setPort('portOfDischarge', port)"
              >
                <v-list-item-avatar>
                  <v-avatar size="24" class="mr-1" left>
                    <v-img
                      contain
                      v-if="port.code"
                      :src="`https://cdn.loglive.io/flags/4x3/${port.code
                        .substring(0, 2)
                        .toLowerCase()}.svg`"
                    ></v-img>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title style="font-size: 14px">
                    {{ port.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 13px; color: grey">
                    {{ port.code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon
                    color="success"
                    v-if="port.code == booking.portOfDischargeValue"
                  >
                    check_circle_outline
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else-if="booking.consigneeProfile">
            <v-progress-linear
              v-if="loadingDestinationPorts"
              color="primary"
              indeterminate
            >
            </v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="filterDestinationPort.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="filterDestinationPort"
                    :key="destinationPortKey"
                    height="400"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="favouritePort(item, 'MARK', 'Destination')"
                            v-if="!item.organisationPorts[0]"
                            ><v-icon>star_border</v-icon></v-btn
                          >
                          <v-btn
                            :key="originPortKey"
                            icon
                            @click="
                              favouritePort(item, 'UNMARK', 'Destination')
                            "
                            v-if="item.organisationPorts[0]"
                            ><v-icon color="yellow">star</v-icon></v-btn
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-if="filterDestinationPort.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="font-size: 13px; color: grey"
                        >No ports found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
          <div v-else>
            <v-progress-linear
              v-if="loadingPortSearch"
              color="primary"
              indeterminate
            ></v-progress-linear>
            <v-expand-transition>
              <v-card flat class="my-0 py-0">
                <v-card-text class="my-0 py-0">
                  <v-virtual-scroll
                    v-if="portResults.length > 0"
                    class="my-0 py-0"
                    :bench="0"
                    :items="portResults"
                    height="400"
                    item-height="50"
                  >
                    <template v-slot:default="{ item }">
                      <v-list-item
                        :key="item.id"
                        @click="setPort('portOfDischarge', item)"
                      >
                        <v-list-item-avatar>
                          <v-avatar size="24" class="mr-1" left>
                            <v-img
                              contain
                              v-if="item.locode"
                              :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                                .substring(0, 2)
                                .toLowerCase()}.svg`"
                            ></v-img>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 14px">
                            {{ item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            style="font-size: 13px; color: grey"
                          >
                            {{ item.locode }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-virtual-scroll>
                  <v-list-item v-else>
                    <v-list-item-content class="text-center">
                      <span style="color: grey; font-size: 13px"
                        >No results found</span
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Final Destination Modal -->
    <v-dialog
      v-model="fdModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2" color="grey">location_on</v-icon> Manage Final
            Destination
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="redPop"
            @click="(fdModal = false), (portSearch = null)"
          >
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <template>
            <v-container class="pa-0">
              <!-- Chips Container -->
              <v-chip-group
                v-model="selectedTransportModes"
                active-class="primary--text"
                multiple
              >
                <v-chip
                  v-for="transport in transportModes"
                  :key="transport"
                  :value="transport"
                  filter
                  small
                >
                  {{ transport }}
                </v-chip>
              </v-chip-group>
            </v-container>
          </template>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="portSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="loadingPortSearch"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="portResults.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="portResults"
                  height="400"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item
                      :key="item.id"
                      @click="setPort('finalDestination', item)"
                    >
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 13px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 13px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Booking Office Modal -->
    <v-dialog
      v-model="bookingOfficeModal"
      width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card
        style="
          background-color: rgba(0, 0, 0, 0.2) !important;
          background: rgba(0, 0, 0, 0.2) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 10px;
        "
      >
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px">
            <v-icon class="mr-2">location_on</v-icon> Manage Booking Office
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="bookingOfficeModal = false">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="my-0 py-0"
            autofocus
            prepend-inner-icon="search"
            dense
            placeholder="Search"
            outlined
            clearable
            v-model="bookingOfficeSearch"
            hide-details
          ></v-text-field>
          <v-progress-linear
            v-if="bookingOfficeLoading"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <v-expand-transition>
            <v-card flat class="my-0 py-0">
              <v-card-text class="my-0 py-0">
                <v-virtual-scroll
                  v-if="bookingOfficePlaces.length > 0"
                  class="my-0 py-0"
                  :bench="0"
                  :items="bookingOfficePlaces"
                  height="400"
                  item-height="50"
                >
                  <template v-slot:default="{ item }">
                    <v-list-item :key="item.id" @click="setBookingOffice(item)">
                      <v-list-item-avatar>
                        <v-avatar size="24" class="mr-1" left>
                          <v-img
                            contain
                            v-if="item.locode"
                            :src="`https://cdn.loglive.io/flags/4x3/${item.locode
                              .substring(0, 2)
                              .toLowerCase()}.svg`"
                          ></v-img>
                        </v-avatar>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 14px">
                          {{ item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                          style="font-size: 13px; color: grey"
                        >
                          {{ item.locode }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-virtual-scroll>
                <v-list-item v-else>
                  <v-list-item-content class="text-center">
                    <span style="color: grey; font-size: 13px"
                      >No results found</span
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-card-text>
            </v-card>
          </v-expand-transition>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
    <script>
export default {
  props: {
    booking: {
      type: Object,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    allOriginPorts: [],
    allDestinationPorts: [],
    bookingOfficeModal: false,
    bookingOfficeLoading: false,
    bookingOfficePlaces: [],
    bookingOfficeQuery: null,
    bookingOfficeSearch: null,
    bookingOfficeTimeout: null,
    countryType: null,
    countryModal: false,
    destinationPortKey: 50,
    loadingDestinationPorts: false,
    loadingOriginPorts: false,
    loadingPortSearch: false,
    originPortKey: 0,
    portResults: [],
    portSearch: null,
    portSearchQuery: null,
    portSearchTimeout: null,
    polModal: false,
    podModal: false,
    fdModal: false,
    searchCountries: null,
    searchDestination: null,
    searchOrigin: null,
    selectedTransportModes: ["Port"],
    transportModes: ["Rail", "Road", "Port", "Airport"],
  }),
  watch: {
    async bookingOfficeSearch(val) {
      if (this.bookingOfficeLoading) {
        clearTimeout(this.bookingOfficeTimeout);
      }
      if (val && val.length > 2) {
        if (this.bookingOfficeLoading && this.bookingOfficeQuery) {
          this.bookingOfficeQuery.abort();
        }
        this.bookingOfficeLoading = true;
        let params = {
          search: val,
        };
        if (this.booking.originCountry) {
          params.filter = {
            countryCode: this.booking.originCountry.iso2,
          };
        }

        this.bookingOfficeTimeout = setTimeout(async () => {
          this.bookingOfficeQuery = new AbortController();
          const signal = this.bookingOfficeQuery.signal;
          this.bookingOfficePlaces = await this.$API.searchTowns({
            params: params,
            signal,
          });
          this.bookingOfficeLoading = false;
        }, 500);
      } else {
        clearTimeout(this.bookingOfficeTimeout);
        this.bookingOfficeLoading = false;
      }
    },
    "booking.consigneeProfileId": {
      handler: function (val) {
        if (this.booking.consigneeProfile) {
          this.getAllPorts(this.booking.consigneeProfile);
        }
      },
      immediate: true,
    },

    async portSearch(val) {
      if (this.loadingPortSearch) {
        clearTimeout(this.portSearchTimeout);
      }
      if (this.loadingPortSearch && this.portSearchQuery) {
        this.portSearchQuery.abort();
      }
      this.loadingPortSearch = true;
      let params = {
        search: val,
        portType: this.selectedTransportModes,
      };
      this.portSearchTimeout = setTimeout(async () => {
        this.portSearchQuery = new AbortController();
        const signal = this.portSearchQuery.signal;
        this.portResults = await this.$API.searchTowns({
          params: params,
          signal,
        });
        this.loadingPortSearch = false;
      }, 500);
    },
  },
  computed: {
    filterCountries() {
      let result = this.countries;
      if (this.searchCountries) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchCountries.toLowerCase())
        );
      }
      return result;
    },
    filterDestinationPort() {
      let result = this.allDestinationPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            x.name
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase()) ||
            x.locode
              .toLowerCase()
              .includes(this.searchDestination.toLowerCase())
        );
      }
      return result;
    },
    filterOriginPort() {
      let result = this.allOriginPorts;
      result = result.sort((a, b) => {
        let portA = a.organisationPorts && a.organisationPorts.length > 0;
        let portB = b.organisationPorts && b.organisationPorts.length > 0;
        if (portA && portB) {
          return a.name.localeCompare(b.name);
        } else if (portA) {
          return -1;
        } else if (portB) {
          return 1;
        } else {
          return 0;
        }
      });

      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            x.name.toLowerCase().includes(this.searchOrigin.toLowerCase()) ||
            x.locode.toLowerCase().includes(this.searchOrigin.toLowerCase())
        );
      }
      return result;
    },
    dischargePorts() {
      let result =
        this.booking.consigneeProfile &&
        this.booking.consigneeProfile.consigneeProfilePorts
          ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
              (x) => x.type == "discharge"
            )
          : [];
      if (this.searchDestination) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase())) ||
            (x.code &&
              x.code
                .toLowerCase()
                .includes(this.searchDestination.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
    loadingPorts() {
      let result =
        this.booking.consigneeProfile &&
        this.booking.consigneeProfile.consigneeProfilePorts
          ? this.booking.consigneeProfile.consigneeProfilePorts.filter(
              (x) => x.type == "loading"
            )
          : [];
      if (this.searchOrigin) {
        result = result.filter(
          (x) =>
            (x.name &&
              x.name.toLowerCase().includes(this.searchOrigin.toLowerCase())) ||
            (x.code &&
              x.code.toLowerCase().includes(this.searchOrigin.toLowerCase()))
        );
      }
      let unique = [...new Set(result.map((x) => x.code))];
      unique = unique.map((x) => result.find((y) => y.code == x));
      unique.sort((a, b) => (a.code > b.code ? 1 : b.code > a.code ? -1 : 0));
      return unique;
    },
  },
  methods: {
    async favouritePort(item, type, arrayType) {
      if (arrayType == "Origin") {
        let index = this.allOriginPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allOriginPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allOriginPorts.unshift(port[0]);
            this.originPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      } else {
        let index = this.allDestinationPorts.findIndex((x) => x.id == item.id);
        if (type == "MARK") {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [{ ...item }];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.createOrgFavouritePort({
            unLocodeId: item.id,
          });
          result
            ? this.$message.success("Port added to favourites")
            : this.$message.error("Error adding port to favourites");
        } else {
          if (index > -1) {
            let port = this.allDestinationPorts.splice(index, 1);
            port[0].organisationPorts = [];
            this.allDestinationPorts.unshift(port[0]);
            this.destinationPortKey++;
          }
          let result = await this.$API.updateOrgFavouritePort({
            unLocodeId: item.id,
          });
          if (!result) {
            this.$message.error("Error removing port from favourites");
          }
        }
      }
    },
    async getAllPorts(profile) {
      if (profile.originCountry) {
        this.allOriginPorts = await this.$API.getPortsByCountry(
          profile.originCountry.iso2
        );
      }
      if (profile.destinationCountry) {
        this.allDestinationPorts = await this.$API.getPortsByCountry(
          profile.destinationCountry.iso2
        );
      }
    },
    setBookingOffice(office) {
      this.booking.bookingOffice = office.locode;
      this.booking.bookingOfficeName = office.name;
      this.bookingOfficeModal = false;
      this.$emit("bookingChange");
    },
    setCountry(country) {
      if (this.countryType == "Origin") {
        this.booking.originCountry = country;
        this.booking.originCountryId = country.id;
      } else if (this.countryType == "Destination") {
        this.booking.destinationCountry = country;
        this.booking.destinationCountryId = country.id;
      }
      this.countryModal = false;
      this.$emit("bookingChange");
    },
    setPort(type, item) {
      if (type == "portOfLoad") {
        this.booking.portOfLoadValue = item.locode ?? item.code;
        this.booking.portOfLoadCity = item.name;
        if (!this.booking.originCountry) {
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.portOfLoadValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.originCountry = findCountry;
            this.booking.originCountryId = findCountry.id;
          }
        }
      } else if (type == "portOfDischarge") {
        this.booking.portOfDischargeValue = item.locode ?? item.code;
        this.booking.portOfDischargeCity = item.name;
        if (!this.booking.finalDestinationValue) {
          this.booking.finalDestinationValue = item.locode ?? item.code;
          this.booking.finalDestinationCity = item.name;
          let findCountry = this.countries.find(
            (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
          );
          if (findCountry) {
            this.booking.destinationCountry = findCountry;
            this.booking.destinationCountryId = findCountry.id;
          }
        }
      } else if (type == "finalDestination") {
        this.booking.finalDestinationValue = item.locode ?? item.code;
        this.booking.finalDestinationCity = item.name;
        let findCountry = this.countries.find(
          (x) => x.iso2 == this.booking.finalDestinationValue.substring(0, 2)
        );
        if (findCountry) {
          this.booking.destinationCountry = findCountry;
          this.booking.destinationCountryId = findCountry.id;
        }
        if (!this.booking.portOfDischargeValue) {
          this.booking.portOfDischargeValue = item.locode ?? item.code;
          this.booking.portOfDischargeCity = item.name;
        }
      }
      this.portSearch = null;
      this.polModal = false;
      this.podModal = false;
      this.fdModal = false;
      this.$emit("bookingChange");
    },
  },
};
</script>